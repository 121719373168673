.container {
  margin: 8px 0;
}

.score {
  display: inline;
  margin-right: 8px;
}

.scores {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}
