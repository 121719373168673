/* Dropdown content */
.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.detailsSelect {
  flex: 1;
}
