.container {
  width: 100%;
}

.content {
  margin-top: 24px;
}

.section + .section {
  margin-top: 40px;
}

.sectionHead {
  margin-bottom: 8px;
  text-transform: uppercase;
  color: var(--captions);
  font-size: 12px;
  font-weight: 600;
}

.example + .example {
  margin-top: 24px;
}
