.container {
  position: relative;
  min-width: 400px;
}

.containerFullWidth {
  min-width: 0;
  width: 100%;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--separator);
}

@media screen and (max-width: 1400px) {
  .field {
    max-width: 450px;
  }
}

.goalWrapper {
  position: relative;
  margin-bottom: 16px;
}

.pitchWrapper {
  position: relative;
}

.pitchOverlay {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.pitchImage {
  min-height: 1px;
  display: block;
  max-width: 100%;
}

.canvasWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.playButton {
  display: flex;
  justify-content: flex-end;
}

.dropdown {
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 0;
}

.teamLogo {
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translateX(-50%);
  pointer-events: none;
}

.teamLogoTransparent {
  opacity: 0.4;
}

/* Defending actions changes */
.containerDefending,
.containerOwnHalf {
  .pitchImage {
    transform: rotate(180deg);
  }

  .dropdown {
    left: auto;
    top: auto;
    right: 8px;
    bottom: 8px;
  }
}

.containerOwnHalf {
  .teamLogo {
    display: none;
  }
}
