.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.body {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
