.container {
  display: flex;
  // grid-template-columns: 1fr auto;
  flex-direction: row;
  gap: 24px;

  position: relative;
}
.content {
  flex: 1;
}
