.content {
  display: flex;
  width: 100%;
  position: fixed;
}

/* Fix close X button styling in modal */
.button-fix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.button-fix > span {
  display: flex;
  align-self: center;
}

/* Swiper */
.swiper-slide {
  width: 100% !important;
}

.swiper-pagination-bullet {
  background: var(--hover) !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}
