.widget {
  border: 2px dashed var(--captions);
  border-radius: 8px;
  padding: 24px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}

.widget:not(.emptyWidget):hover {
  cursor: grab;
  border: 2px dashed var(--light-green);
}

.widget:not(.emptyWidget):active {
  cursor: grabbing;
  border: 2px dashed var(--light-green);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.image {
  width: 100%;
  max-height: 170px;
}

.imageWrapper {
  flex: 1;
}

.imageBg {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  // min-width: 200px;
}

.emptyWidget {
  display: flex;
  justify-content: center;
  align-items: center;

  & {
    color: var(--captions);
  }
}
