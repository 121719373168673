.card {
  box-sizing: border-box;
  // background: var(--surface);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fields {
  margin-top: 32px;
}

.bottomContent {
  margin-top: 32px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 24px;
  margin-top: auto;
}

.actionSecondary {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.title {
  margin: 0;
  font-size: 16px;
}
