.container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.wrapper {
  height: 10px;
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
}

.bar {
  width: 100%;
  margin: 0;
  height: 6px;
}

.red {
  background: #ad2929;
  border-radius: 3px 0 0 3px;
}

.yellow {
  background: #ffb74d;
}

.green {
  background: #00875a;
  border-radius: 0 3px 3px 0;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #333;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  img {
    width: 100%;
    margin-top: -3px;
  }
}

.iconDummy {
  background-color: var(--white-color);
  height: 12px;
  width: 12px;
}

.value {
  font-size: 14px;
  font-weight: 600;
}
