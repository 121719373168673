.container {
  // margin-top: 8px;
  // margin-bottom: 8px;
  // padding-left: 16px;
  // padding-top: 8px;
  // padding-bottom: 8px;
  // border-left: 1px dashed var(--hover);
  padding-top: 8px;
  padding-bottom: 8px;
}

.containerReadonly {
  // padding-left: 8px;
  // padding-top: 4px;
  // padding-bottom: 4px;

  .andOr {
    margin: 8px 0;
  }
}

.andOr {
  margin: 24px 0;
  display: flex;
}

.conditions-small .andOr {
  margin: 8px 0;
  // background-color: red;
}

/* Add condition button */
.addCondition {
  margin-top: 24px;
}

.addConditionPopup {
  background-color: var(--separator);
}

/* Single Action */
.conditionWrapper {
  display: flex;
  flex-direction: row;

  margin-bottom: 2px;
  &:hover {
    .conditionDelete {
      display: block;
    }
  }
}
.condition {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.conditionDelete {
  align-self: center;
  display: none;
  margin-left: 24px;
}

.actionIndexWrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 1;
    border: 1px dashed var(--hover);
    top: 0;
    bottom: 0;
    left: 10px;
  }
}

.actionIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  box-sizing: border-box;
  font-weight: bold;
  margin-right: 8px;
  color: var(--captions);
  background-color: var(--surface);
  border-color: var(--captions);
  border-width: 1px;
  border-style: solid;
  z-index: 10;
  position: relative;
}

.addCondition {
  margin-top: 8px;
}
