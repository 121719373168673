.playlistBarOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  border-top: 1px solid var(--background);

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .playlistBarShare {
    margin-right: 10px;
    gap: 8px;
  }

  .downloadSpinner {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .playlistBarAutoplayToggle {
    position: relative;
    left: -3px;
  }

  .playlistBarDownloadIcon {
    gap: 2px;
  }

  .playlistBarSelectedVideos {
    color: var(--captions);
    font-size: 12px;
  }

  .red {
    color: var(--system-red);
  }
}
