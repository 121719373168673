.multiSelectPlus {
  margin-left: 4px;
  display: inline-block;
}

.title {
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 12px;
  color: var(--captions);
}

/* Trigger */
.trigger {
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  border: 0;
  padding: 0;
  text-align: left;
  font-family: 'Inter', sans-serif;

  &:disabled {
    opacity: 0.5;
  }
}

.triggerItem {
  display: inline-block;
  padding: 4px 4px;
  position: relative;
  color: var(--primary-text-color);
  font-weight: 500;

  &::before {
    content: '';
    position: absolute;
    width: '100%';
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: var(--white-rgba1);
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &::after {
    content: '';
    position: absolute;
    width: '100%';
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: var(--accent);
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  .trigger:not(:disabled) &:hover {
    &::before {
      background-color: var(--primary-text-color);
    }
  }
}

.triggerItemWithValues {
  color: var(--primary-text-color);
}

.triggerItemRequired {
  color: var(--captions);
}

.triggerItemError {
  &::before {
    background-color: var(--primary-text-color);
  }
  &::after {
    background-color: var(--system-red);
    transform: scaleX(1);
  }
}

.triggerItemOpened {
  &::before {
    background-color: var(--primary-text-color);
  }
  &::after {
    transform: scaleX(1);
  }
}

.triggerItemProperty {
  + .triggerItemProperty {
    margin-left: 4px;
  }
}

.triggerItemProperty-medium {
  + .triggerItemProperty-medium {
    margin-left: 8px;
  }
}

.triggerItemProperty-small {
  + .triggerItemProperty-small {
    margin-left: 4px;
  }
}

.triggerItemPropertySeparator {
  margin-left: 4px;
  display: inline-block;
}

.triggerItemPropertySeparator-medium {
  margin-left: 8px;
}

.triggerItemPropertySeparator-small {
  margin-left: 4px;
}

/* Size */
.triggerItem-large {
  font-size: 24px;
}

.triggerItem-medium {
  font-size: 24px;
}

.triggerItem-small {
  font-size: 16px;
}
