.line {
  + .line {
    margin-top: 8px;
  }

  + .buttonLine {
    margin-top: 32px;
  }

  gap: 6px;
}

.line,
.lineSegment {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.section {
  + .section {
    margin-top: 32px;
  }

  + .sectionOffset {
    margin-top: 32px;
  }
}

.sentenceReadonly {
  .line {
    + .line {
      margin-top: 4px;
    }
  }

  .section {
    + .section {
      margin-top: 4px;
    }

    + .sectionOffset {
      margin-top: 8px;
    }
  }
}

.sentence {
  &.sentence-small,
  &.sentence-xsmall {
    .line {
      + .line {
        margin-top: 5px;
      }

      gap: 4px;
    }

    .section + .section {
      margin-top: 16px;
    }
  }
}
