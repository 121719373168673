.menuItem {
  display: block !important;
}

.label {
  display: block;
}

.possibleValues {
  display: block;
  font-size: 12px;
  color: var(--captions);
}
