.container {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-left: 1px dashed var(--hover);
}

.containerReadonly {
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;

  .andOr {
    margin: 8px 0;
  }
}

.andOr {
  margin: 24px 0;
  display: flex;
}

.conditions-small .andOr {
  margin: 8px 0;
  // background-color: red;
}

/* Add condition button */
.addCondition {
  margin-top: 24px;
  opacity: 0.3;
}

.addConditionPopup {
  background-color: var(--separator);
}

/* Single Condition */
.condition {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    .conditionDelete {
      display: block;
    }
  }
}

.conditionDelete {
  display: none;
  margin-left: 24px;
}

.actionIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--hover);
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  color: var(--text-primary);
  background-color: var(--background-secondary);
}
