.chart {
  margin-top: 16px;
  width: 100%;
}

.dataExplorerEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 325px;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #9b9b9b;
  }
}

.emptyTab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 8px;
  width: 100%;
  text-align: center;
  min-height: 296px;
  box-sizing: border-box;
}
