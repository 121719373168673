/* Trigger */
.trigger {
  border-radius: 3px;
  display: flex;
  padding: 0 10px;
}

.triggerVisual {
  display: flex;
  align-items: center;
  height: 32px;
}

.dropdownItemInner {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.dropdownItemInnerLabel {
  margin-left: 12px;
}

/* Options */
.option {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 12px;
  width: 36px;
}

.optionStart {
  background-color: var(--light-green);
  height: 12px;
  left: 0;
  position: absolute;
  width: 12px;
  z-index: 1;
}

.optionLine {
  background-color: var(--hover);
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
}

.optionLineActive {
  background-color: var(--light-green);
}

.optionEnd {
  background-color: var(--light-green);
  border-radius: 50%;
  height: 12px;
  margin-left: 12px;
  position: absolute;
  right: 0;
  width: 12px;
  z-index: 1;
}

.optionEndActive {
}
