.container {
  // display: flex;
  // flex-direction: column;
  // gap: 8px;

  // border-radius: 8px;
  // overflow-y: auto;
  // min-width: 300px;
}

.input {
  display: flex;
  align-items: center;
  gap: 16px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}

.itemName::first-letter {
  text-transform: capitalize;
}

h2 {
  margin: 0;
}
