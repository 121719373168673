.container {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

@media screen and (min-width: 1400px) {
  .container {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
}

@media screen and (min-width: 2000px) {
  .container {
    grid-template-columns: repeat(4, minmax(400px, 1fr));
  }
}
