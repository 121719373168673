.dropdownContent {
  padding: 24px;
  min-width: 480px;
}

.addButton {
  margin-top: 16px;
}

.line {
  + .line {
    margin-top: 16px;
  }
}

.andOr {
  margin-top: 16px;
  display: flex;
}

.andOrText {
  color: var(--captions);
}
