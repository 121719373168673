.block {
  width: 100%;
  & + .block {
    margin-top: 24px;
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 8px;
  }
}

.blockFixed {
  width: 100%;
  @media (min-width: 1400px) {
    width: 100%;
  }
}
