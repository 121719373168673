/* Tooltip */
.tooltip {
  max-width: 360px;
  padding: 16px;
  background-color: var(--separator);
}

.tooltipTrigger {
  padding: 0;
  text-align: left;
  border: 0;
  background-color: transparent;

  &.tooltipTriggerOpened {
    color: var(--light-gray);
  }
}
