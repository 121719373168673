.dropdownButton {
  height: 40px !important;
  align-items: center !important;
  font-weight: 400 !important;

  > span:nth-of-type(2) {
    text-align: left;
    margin: 0 32px 0 10px;
  }
}

div[role='menu'] {
  max-height: 50vh !important;
}

.buttons {
  padding: 24px;
  display: flex;
  flex-direction: column;
}
