.container {
  // background: var(--background);
  // border-radius: 8px;
  color: var(--white-color);
  padding: 16px 12px;
  // position: absolute;
}

.players {
  display: flex;
  align-items: center;
}

.firstPlayer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}

.data {
  display: flex;
  border-top: 1px solid var(--separator);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 8px;
  padding-top: 8px;
}

.gameInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  // font-size: 14px;
  white-space: nowrap;
}

.scoreWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score {
  margin: 0 4px;
}

.dateInfo {
  display: flex;
  margin-top: 4px;
  flex-direction: row;
}

.date {
  opacity: 0.5;
  font-size: 12px;
}

.stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.time {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
}

.metric {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
}

.eventType {
  opacity: 0.5;
  font-size: 0.875rem;
  margin-bottom: 12px;
  font-weight: 100;
}
