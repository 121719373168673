.modal {
  background-color: var(--surface);
}

.explanationText {
  width: 50%;
  margin-bottom: 32px;
  color: var(--captions);
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--separator);
  border: 1px solid var(--captions);
  border-radius: 8px;
  padding: 0 16px 24px 16px;
}

.items {
  margin-top: 64px;
}

.imgContainer {
  position: relative;
  width: 100%;
  max-width: 420px;
}

.imgContainer::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  box-shadow: inset 0 0 24px 24px var(--separator);
}

// .img {
//   position: relative;
//   width: 480px;
//   display: block;
//   opacity: 0.8;
// }
.img {
  width: 100%;
  height: 0;
  padding-top: 43.16%;
  background-size: contain;
  background-position: center center;
  opacity: 0.8;
}

.content {
  padding-bottom: 8px;
}

.content h2 {
  color: var(--captions);
}

.section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.example {
  border: 1px solid var(--captions);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 160px;

  padding: 24px;
  font-size: 16px;
}

.exampleDummy {
  line-height: 1.8;
}

.bottomRow {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-top: 16px;
  color: var(--captions);

  > p {
    margin-top: auto;
    font-size: 12px;
  }
  button {
    display: block;
    margin-top: 24px;
  }
}
