.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.title::first-letter {
  text-transform: capitalize;
}

.title:hover {
  color: var(--light-green);
}

.link {
  color: var(--white);
  display: block;
  text-decoration: none;

  &:hover {
    color: var(--light-green);
    text-decoration: none;
  }
}
