.container {
  position: relative;
  bottom: 76px;
  width: 100%;
  height: 36px;
  z-index: 3;
  overflow: visible;
  background: var(--hover);
  display: block;
}

.timeline_container {
  display: grid;
  grid-template-columns: 35px auto 35px;
  height: 100%;
}

.timeline {
  position: relative;
}

.skeleton {
  background: rgba(0, 0, 0, 0.3) !important;
}

.timestamp {
  position: absolute;
  margin: 0 !important;
  z-index: 6;
  user-select: none;
  opacity: 0.5;
}

.dummy_timestamp {
  visibility: hidden;
  position: absolute;
}

// TODO custom grid css that we should change with library grid once we migrate

.grid_container {
  display: grid;
  grid-template-columns: 10vw auto 10vw;
  height: 100%;
}

.vertical_grid_container {
  display: grid;
  justify-content: center;
  align-content: space-around;
}

.icon_btn {
  background-color: transparent;
  border: none; /* Remove borders */
  padding: 0 5px; /* Some padding */
  cursor: pointer; /* Mouse pointer on hover */
  transition: 0.5s;
}

.icon_btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.timeline_line {
  width: 2px;
  height: 100%;
  background: red;
  position: absolute;
  top: 0;
  z-index: 3;
}

.timeline_handle {
  background: white;
  width: 15px;
  height: 100%;
  border-radius: 25px;
  position: absolute;
  cursor: col-resize;
  z-index: 10;
}

.timeline_selected_overlay {
  position: absolute;
  background: #1d7afc;
  height: 100%;
  cursor: grab;
  top: 0;
}

.left_chevron {
  position: absolute !important;
  left: 0;
}

.right_chevron {
  position: absolute !important;
  right: 0;
}

.icon {
  color: white !important;
}

//TODO create general class for this
.center {
  width: 100%;
  height: 100%;
}
