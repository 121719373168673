.container {
  padding-bottom: 16px;
  min-height: 450px;
  // min-width: 800px;
  display: flex;
  flex-direction: column;
}

.topControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;
}

.typeSelector {
  // flex: 1;
}

.targetSwitch {
  margin-left: 64px;
}

.sentence {
  flex: 1;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
  }
}
