.dropdownContent {
  padding: 24px;
  min-width: 320px;
  max-width: 450px;
}

.subjects {
  margin-top: 16px;
}

// .subjectGroup + .subjectGroup {
//   margin-top: 4px;
// }

.subjectGroupContent {
  margin-top: 8px;
  margin-bottom: 24px;
}
