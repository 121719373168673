//TODO create general class for this
.center {
  display: grid;
  justify-content: center;
  align-content: center;
}

.v_center {
  display: grid;
  align-content: center;
}

.h_center {
  display: grid;
  justify-content: center;
}

.videoPlaylistBarButton {
  position: absolute;
  top: 16px;
  right: 16px;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid var(--surface);

  width: 320px;
  // width: 33%;
  height: 100%;
  background-color: var(--surface);
}

.playlistBarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 32px;
  padding: 16px 16px 16px 16px;
}
.playlistBarContent {
  overflow-y: auto;
  flex: 1;
  // padding: 24px 0;

  .playlistBarExit {
    position: relative;
    bottom: 10px;
    right: 8px;
  }
}

.playlistBarHeaderButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
}

.selectAll {
  flex: 1;
}

.playlistItemList {
  margin-top: 8px;
}

.Accordion {
  color: white !important;
  background: transparent !important;
  margin: 0 !important;
}

.AccordionSummary {
  background: var(--background) !important;

  min-height: 32px !important;
}

.AccordionDetails {
  padding: 0 0 !important;
}

.playlistItemResult {
  display: flex;
  flex-direction: row;
  align-items: center;

  .playlistItemScore {
    white-space: nowrap;
    margin: 0 4px;
    font-weight: bold;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}
