.playerTabs {
  margin-bottom: 16px;
}

.playerInfo {
  display: flex;
}

.playerName {
  margin-left: 8px;
  //   display: flex;
  //   align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.receivedPlayerPointer {
  margin: 4px 8px 0 8px;
}

.playersList {
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  //margin-top: 16px;

  // Last centered column should not have left padding
  th:last-child {
    padding-left: 0;
  }
}

.playerCell {
  height: 34px;
  display: flex;
  align-items: center;
  width: 100%;
}

.playerCellInteractive {
  cursor: pointer;
}

.playerStatWrapper {
  display: flex;

  // width: 100%;
  //height: 225px;
  margin-bottom: -24px; // Compensate for AtlasKit
}

.playerNameWrapperTruncated {
  display: flex;
  max-width: 90px;
}

.table {
  flex: 1;

  thead {
    border-bottom: 1px solid var(--separator) !important;
  }

  tbody tr:hover {
    background-color: var(--hover);
  }

  tbody td {
    border-top: 1px solid var(--separator);
    padding: 0;
  }

  thead th:hover {
    background-color: var(--hover);
  }

  tr[data-ts--dynamic-table--table-row--highlighted='true'] {
    background-color: var(--captions);
    // background-color: var(--accent);
  }
}

.table + .table {
  margin-left: 16px;
}

.playersListInteractive {
  cursor: pointer;
}

.playersListInteractive:hover {
  text-decoration: underline;
}

.playerStatsEmpty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 260px;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #9b9b9b;
  }
}

.empty {
  display: block;
  padding: 0;
  padding-bottom: 14px;
}

.tabLabel::first-letter {
  text-transform: capitalize;
}
