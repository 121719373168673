.container {
  display: flex;
  flex-direction: column;
  gap: 2px;

  max-height: 680px;
  overflow-y: auto;
  border-radius: 8px;
}

.triggerContainer {
  display: flex;
  width: 100%;
  padding-right: 8px;
}

.matchInfo {
  display: flex;
  align-items: center;
  gap: 4px;
}

.match {
  flex: 1;
}

.matchDay {
  display: flex;
  align-items: center;
  gap: 4px;
}

.info {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
}

.playVideosButton {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 40px;
}

.collapsibleContainer {
  background-color: var(--separator);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

  gap: 8px;
}

.video {
  background-color: var(--hover);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  span {
    font-weight: bold;
  }

  .icon {
    margin-left: 16px;
  }
}

.caption {
  color: var(--captions);
  font-size: 12px;
  margin-top: 4px;
  font-weight: 500;
}

.notAvailable {
  color: var(--captions);
  opacity: 0.6;
  display: flex;
  align-items: center;
  margin-left: 32px;
}
