@import '../../../variables/style_variables.scss';

.dashboardScreen {
  min-height: calc(100vh - 72px);
}

.dashboard {
  // overflow: scroll;
  // overflow-y: scroll;
  height: 100%;
  position: relative;

  * {
    box-sizing: border-box;
  }
}

.header {
  // border-bottom: 1px solid var(--separator);
  padding: 16px 24px 8px 24px;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContainer {
  display: flex;
  gap: 16px;
}

.bottomRow {
  margin-top: 16px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0;

  &::first-letter {
    text-transform: capitalize;
  }
}

.content {
  width: 100%;
  position: relative;
  // display: grid;
  // grid-template-columns: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contentSingle {
  margin: 24px;
  margin-bottom: 0;
  padding-bottom: 24px;
  gap: 24px;
}

.topRowButtons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contentEmpty {
  display: block;
}

.emptyImage {
  margin-bottom: 40px;
}

.row {
  flex: 1;
}

@media screen and (min-width: 1400px) {
  .content {
    flex-direction: row;
    .row {
      flex: 0;
    }

    .row:first-of-type {
      flex: 1;
    }
  }

  .contentHalf {
    .row {
      flex: 1;
    }
  }
}

.table {
  margin-top: 8px;
}

.playersStatTable {
  margin: 0 20px 0 32px;
}

.dataExplorer {
  margin-top: 24px;
  max-height: 100px;
}
