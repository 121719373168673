.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.teamName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamNameTruncated {
  max-width: 5vw;
}
