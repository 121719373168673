.container {
  position: relative;
  width: 95vw;
  height: 54vw;
  overflow: hidden;
  // border-radius: 16px;
  background-color: var(--background);
  z-index: 0;
}

.videoClickWrapper {
  width: 100%;
  height: 100%;
}

.max_h {
  height: 100%;
  position: relative;
}
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  align-content: center;
  justify-content: center;
  background-size: cover;
  background: var(--background);
}

.content_loading {
  width: 100%;
  height: 100%;
  position: absolute;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: var(--captions);
  font-size: 14px;
}
