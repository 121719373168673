.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.playerName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playerNameTruncated {
  max-width: 5vw;
}

.tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.tooltipName {
  padding-top: 8px;
  font-size: 1.7em;
}

.info {
  display: flex;
  flex-direction: column;
}
