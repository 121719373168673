//TODO create general class for this
.center {
  display: grid;
  justify-content: center;
  align-content: center;
}

.tags {
  text-align: left;

  font-size: 11px;
  line-height: 14px;
  opacity: 0.6;
  text-align: left;
  width: 100%;
}

.playlistItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
}

.playlistItemLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 8px;
  flex: 1;
}

.playlistItemThumbnail {
  position: relative;
  // min-height: 48px;
  // width: 100%;
  padding: 8px 12px;
  color: white !important;
  background-color: var(--surface);
  border: 0;
  border-radius: 1px;
}

.playlistItemThumbnailImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0.4;
}

.playlistItemThumbnailIcon {
  position: relative;
  z-index: 1;
  height: 24px;
}

.eventText {
  // flex: 1;
  overflow: hidden;
  width: 100%;
}

.time {
  text-align: left;
  font-size: 13px;
  font-weight: 300;
}

.playlistItemResult {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;

  .playlistItemScore {
    margin: 0 4px;
    font-weight: bold;
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.successful {
  padding-right: 8px;
}

.playlistItemFocused {
  // background-color: var(--hover);

  .playlistItemThumbnail {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.3) !important;
  }

  .playlistItemThumbnailImage {
    opacity: 1;
  }

  .playlistItemThumbnailIcon {
    opacity: 0;
  }
}

.playerName {
  font-size: 13px;
  font-weight: 700;
  padding-left: 4px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // width: 100%;
}

.minute {
  font-size: 14px;
  font-weight: 900;
}

.generalInfo {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// TODO general class
.truncate {
  position: relative;
  flex: 1;
  overflow: hidden;
  text-align: left;
}

.metric {
  font-weight: 700;
}

/* Video not available */
.playlistItemNoVideo {
  .generalInfo {
    opacity: 0.4;
  }
  .tags {
    opacity: 0.6;
  }

  .metric {
    opacity: 0.6;
  }
}

.playlistItemBase {
  // padding: 0.5rem !important;
  display: flex !important;
  justify-content: flex-start !important;
  width: 100%;
  color: white !important;
  text-transform: none !important;
  text-align: left !important;

  &:hover {
    .playlistItemThumbnailImage {
      opacity: 1;
    }
  }
}

.remainingTags {
  margin-left: 0.3rem;
  font-weight: 700;
  display: inline;
  font-size: 11px;
  transform-origin: left;
  display: inline-block;
  vertical-align: middle;
}

.actionIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--hover);
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  color: var(--text-primary);
  background-color: var(--background-secondary);
}
