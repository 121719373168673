.overview {
  min-height: calc(100vh - 72px);
}

.container {
  padding: 24px;
}

.trendWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  width: 100%;
}
.trend {
  flex: 1;
  width: 0;
}

.grid {
  display: grid;
  gap: 24px;
  margin-top: 56px;
}

@media screen and (min-width: 1400px) {
  .grid {
    grid-template-columns: 1fr 450px;
  }
}
