:root {
  --background: #0e1d28;
  --background_lower_opacity: #0e1d2880;
  --background_darker: #0b1822;
  --captions: #4c6273;
  --surface: #162632;
  --separator: #192b39;
  --hover: #293b4b;

  --light-green: #5ca58b;
  --accent: var(--light-green);

  --system-green: #00875a;

  --dark-red: #313131;
  --system-red: #ca2d2d;

  --primary-text-color: rgba(255, 255, 255, 0.87);
  --white-color: #ffffff;
  --white-rgba1: rgba(255, 255, 255, 0.1);
  --light-gray: #e2e2e2;

  --warning: #ffb74d;

  --zi-loader: 3;

  --font-body: 'Inter', sans-serif;
}
