.container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottomText {
  color: var(--captions);
  font-weight: 500;
  margin-top: 32px;
}

.primaryAction {
  margin-top: 16px;
}

.secondaryAction {
  margin-top: 16px;
}

.title {
  margin-bottom: 16px;
}

.description {
  text-align: center;
  margin-bottom: 32px;
}
