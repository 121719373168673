.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px 0;
  min-height: 100vh;  
  box-sizing: border-box;
}

.form {
  box-sizing: border-box;
  position: relative;
  max-width: 440px;
  width: 90%;
  padding: 54px;
  padding-bottom: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: #192b39;

  p.error {
    color: var(--system-red);
  }
}

.logo {
  margin-bottom: 32px;
  max-width: 320px;
}

@media screen and (max-width: 840px) {
  .logo {
    max-width: 240px;
  }
}

@media screen and (min-width: 1600px) {
  .logo {
    margin-bottom: 64px;
  }
}

.privacy {
  display: flex;
  align-items: center;
  font-size: 12px;
  opacity: 0.5;
  text-align: center;
  padding: 0 40px;
  margin-top: 64px;
}
