.control_bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 44px;
  z-index: 1;
  overflow: visible;
}

.control_bar_sidebar_open {
  width: calc(100% - 320px);
}

.action_bar {
  height: 100%;
}

.popup {
  z-index: 3;
}

.no_pointer_events {
  pointer-events: none;
}

.progress_bar_div {
  cursor: pointer;
  padding-top: 15px;
  margin-top: -15px;
}

.event_indicator {
  background-color: white;
  z-index: 2; /* Ensure it's above other elements */
  position: absolute; /* Ensure positioning */
  width: 4px;
  height: 8px;
  margin-top: -4px;

  transition: transform 0.2s ease-in-out; /* Smooth hover animation */

  &:hover {
    transform: scale(2); /* Enlarge on hover */
  }
}

.progress_time_hover {
  position: absolute;
  bottom: 50px;
  color: white;
}

.IconButton {
  // background-color: transparent !important;
  // transition: 0.5s !important;
  width: 32px !important;
  height: 32px !important;
}

.control_bar_icon {
  width: 24px !important;
  height: 24px !important;
  // color: white !important;
}

// .IconButton:hover {
//   background-color: rgba(0, 0, 0, 0.1) !important;
// }
.IconButton:disabled {
  opacity: 0.5;
}

.full_height_container {
  display: inline-grid;
  height: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.avatar_container {
  display: inline-grid;
  height: 100%;
  width: 30vw;
}

//TODO create general class for this
.v_center {
  display: grid;
  align-content: center;
}

.custom_button {
  font-weight: 501 !important;
  line-height: 1 !important;
  text-transform: none !important;
}

.custom_divider {
  border-color: white !important;
  opacity: 10% !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.audioBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90px;
  gap: 8px;
}

.popupWrapper {
  position: absolute; /* Position the popup relative to the event indicator */
  transform: translateY(-100%); /* Shift the popup above the indicator */
  z-index: 9999;
  background-color: var(--background);
  transition: left 0.3s ease, right 0.3s ease; /* Smooth transition for left and right */
}
