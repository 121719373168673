.icons {
  display: flex;
  gap: 16px;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
