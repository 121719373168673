.overview {
  min-height: calc(100vh - 72px);
}

// .parent {
//   padding-bottom: 8px;
// }

.header {
  padding: 16px 24px 8px 24px;
}

.headerWithBorder {
  border-bottom: 1px solid var(--separator);
}

.topRow {
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.topRowButtons {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.bottomRow {
  margin-top: 16px;
}

.titleContainer {
  display: flex;
  // align-items: center;
  align-items: flex-start;
  gap: 16px;
}
/* .titleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}

.content {
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 24px;
}

.gridItem {
  flex: 1;
}

.pagination {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1400px) {
  .grid {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
}

@media screen and (min-width: 2000px) {
  .grid {
    grid-template-columns: repeat(4, minmax(400px, 1fr));
  }
}

.tagCount {
  color: var(--captions);
  font-weight: bold;
}
