.container {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.text {
  // display: inline-block;
  display: inline;
  font-weight: 300;
  opacity: 0.8;
}

.textUppercase {
  display: inline-block;
}

/* Casing */
.textUppercase::first-letter {
  text-transform: capitalize;
}

/* Type */
.text-value {
  font-weight: 600;
  opacity: 1;
}

.text-operator {
  color: var(--captions);
}

/* Size */
.text-medium {
  font-size: 24px;
}

.text-small {
  font-size: 16px;
}

.text-xsmall {
  font-size: 14px;
}

.text-c-warning {
  color: var(--warning);
  opacity: 0.8;
}

.container button {
  display: inline-block;
  // margin-left: 4px;
}
