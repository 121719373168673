.container {
  // width: 40px;
  * {
    box-sizing: border-box;
  }
}

.container-large {
  // width: 48px;
}

.input {
  border: 0;
  border-bottom: 1px solid var(--captions);
  // box-sizing: content-box;
  background-color: transparent;
  display: block;
  font-weight: 500;
  padding: 2px 4px;
  padding-right: 0;
  width: 100%;

  &::placeholder,
  //&:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: var(--hover);
  }

  &:focus,
  &:active {
    color: var(--accent);
    border-color: var(--accent);
    outline: 0;
  }
}

.inputError {
  border-color: var(--system-red);
}

/* Size */
.input-large {
  font-size: 24px;
  padding-top: 5px;
}

.input-medium {
  font-size: 20px;
}

.input-medium {
  font-size: 18px;
}
