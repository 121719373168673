.container {
  display: flex;
  flex-direction: row;
}

.item {
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.itemDot {
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.itemLabel {
  margin-left: 4px;
  font-size: 12px;
}

.crossed {
  text-decoration: line-through;
  opacity: 0.6;
}
