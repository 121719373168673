.container-xsmall {
  width: 16px;
  height: 16px;
}

.container-small {
  width: 24px;
  height: 24px;
}

.container-medium {
  width: 32px;
  height: 32px;
}

.container-large {
  width: 40px;
  height: 40px;
}

.container-xlarge {
  width: 48px;
  height: 48px;
}

.container-xxlarge {
  width: 56px;
  height: 56px;
}

.image {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.containerBadge {
  // background-color: var(--separator);
  // border: 1px solid var(--captions);
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 2px 2px #000000);
  filter: drop-shadow(0 2px 2px #000000);

  &.container-small {
    height: 24px;
    width: 24px;
    padding: 4px;
    box-sizing: border-box;
  }

  &.container-xsmall {
    height: 16px;
    width: 16px;
    padding: 2px;
    box-sizing: border-box;
  }
}
