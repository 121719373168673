.trend {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: column;
}

.filterButtons {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  align-items: center;

  button {
    text-transform: uppercase;
  }
}

.emptyTrend {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--captions);
}

.showingMatches {
  margin-right: auto;
  color: var(--captions);
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 4px;
}

.disabledSlider {
  height: 10px;
}
