.container {
  max-height: 256px;
  width: 256px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
}

.button {
  margin-bottom: 8px;
}
